interface IUidsPair {
  origin: string
  alter: string
  locale: string
}

interface IUidsToSwitch {
  products: IUidsPair[]
  collections: IUidsPair[]
}

type UidsToReplaceType = { [key: string]: Map<string, string> }
export class ReplaceUids {
  uidsToReplace: UidsToReplaceType

  constructor(uidsToSwitch?: IUidsToSwitch) {
    this.uidsToReplace = this.generateUidsToReplace(uidsToSwitch)
  }

  generateUidsToReplace(uidsToSwitch?: IUidsToSwitch): UidsToReplaceType {
    const abtestUids: UidsToReplaceType = {}

    if (process.env.SITE_VERSION === 'default') {
      return abtestUids
    }

    if (!uidsToSwitch) {
      uidsToSwitch = process.env.ABTEST_UID_SWITCH
        ? (process.env.ABTEST_UID_SWITCH as unknown as IUidsToSwitch)
        : {
            products: [],
            collections: [],
          }
    }

    for (const productUidsPair of uidsToSwitch.products) {
      if (!abtestUids[productUidsPair.locale]) {
        abtestUids[productUidsPair.locale] = new Map()
      }
      abtestUids[productUidsPair.locale]!.set(
        productUidsPair.origin,
        productUidsPair.alter
      )
    }
    for (const collectionUidsPair of uidsToSwitch.collections) {
      if (!abtestUids[collectionUidsPair.locale]) {
        abtestUids[collectionUidsPair.locale] = new Map()
      }
      abtestUids[collectionUidsPair.locale]!.set(
        collectionUidsPair.origin,
        collectionUidsPair.alter
      )
    }

    return abtestUids
  }

  replaceOriginWithAlter(locale: string, uid: string) {
    let u = uid
    const l = locale.slice(0, 2)

    if (this.uidsToReplace[l] && this.uidsToReplace[l].has(uid)) {
      u = this.uidsToReplace[l].get(uid) as string
    }

    return u
  }

  replaceAlterWithOrigin(locale: string, uid: string) {
    let u = uid

    const l = locale.slice(0, 2)

    if (
      this.uidsToReplace[l] &&
      Array.from(this.uidsToReplace[l].values()).includes(uid)
    ) {
      for (const [key, value] of this.uidsToReplace[l]) {
        if (uid === value) {
          u = key
          break
        }
      }
    }

    return u
  }
}

export default new ReplaceUids()
