// import consola from 'consola'

import { Plugin, Context } from '@nuxt/types'
import Customer from '@/entities/Customer'

export class ErrorLogger {
  ctx: Context

  constructor(ctx: Context) {
    this.ctx = ctx

    this.ctx.$sentry.setTags({
      site_version: process.env.SITE_VERSION,
    })
  }

  captureCriticalException(err: Error, options: any = {}) {
    if (this.ctx.nuxtState?.layout === 'slice-machine') {
      return
    }

    this.ctx.$sentry.captureException(err, options)
    throw err
  }

  captureException(err: any, options: any = {}) {
    if (this.ctx.nuxtState?.layout === 'slice-machine') {
      return
    }

    this.ctx.$sentry.setTag('locale', this.ctx.i18n.locale)
    const exception = err.originalError || err.error || err
    this.ctx.$sentry.captureException(exception, options)

    if (
      process.env.NODE_ENV === 'development' ||
      process.env.BUILD_ENV === 'development' ||
      process.server
    ) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  identify(customer: Customer) {
    this.ctx.$sentry.setUser({
      id: customer.id,
      email: customer.email,
      username: `${customer.firstName} ${customer.lastName}`,
    })
  }

  removeIdentify() {
    this.ctx.$sentry.setUser(null)
  }

  static error(err: Error, options: any = {}) {
    // eslint-disable-next-line no-console
    console.error(err, JSON.stringify(options))
    // process.sentry.captureException(err, options)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $logError: ErrorLogger
  }
}

const ErrorLoggerPlugin: Plugin = (context, inject) => {
  inject('logError', new ErrorLogger(context))
}

export default ErrorLoggerPlugin
