import replaceUids from './utils/replaceUids'
import Route from '@/types/prismic/routes'

import linkResolver from '@/plugins/prismic/link-resolver'
import { CONTEXTS } from '@/const'

class LinkResolverService {
  getPathFromLink(locale: string, r: Route) {
    return linkResolver(this.preparePrismicUid(locale, r))
  }

  removeContextFromUid(uid: string): string {
    for (const context of CONTEXTS) {
      if (uid.slice(-2) === `-${context}`) {
        return uid.slice(0, -2)
      }
    }

    return uid
  }

  uidHasContext(uid: string): boolean {
    let has = false

    CONTEXTS.forEach((c) => {
      if (uid.slice(-2) === `-${c}`) {
        has = true
      }
    })

    return has
  }

  getContextFromUid(uid: string): string {
    let context = 'h'
    for (const c of CONTEXTS) {
      if (uid.slice(-2) === `-${c}`) {
        context = c
      }
    }
    return context
  }

  preparePrismicUid(locale: string, route: Route): Route {
    // AB TEST : on modifie le lien des uids modifiés par leur original
    if (route.type === 'product' || route.type === 'collection') {
      route.uid = replaceUids.replaceAlterWithOrigin(locale, route.uid)
    }
    if (
      route.type === 'product' ||
      route.type === 'collection' ||
      route.type === 'blog'
    ) {
      return {
        ...route,
        uid: this.removeContextFromUid(route.uid),
      }
    }

    return route
  }
}

export default new LinkResolverService()
