import axios from 'axios'

import { SHOPIFY_TYPES } from '../const'
import ShopifyBase64 from '../utils/shopifyBase64'

import { PRODUCTS_GRAPHQL } from './graphql/product'

class CollectionService {
  private headers: Object
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }
    if (!process.env.SHOPIFY_API_VERSION) {
      throw new Error('Missing env var SHOPIFY_API_VERSION')
    }
    if (!process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN) {
      throw new Error('Missing env var SHOPIFY_STOREFRONT_ACCESS_TOKEN')
    }

    this.baseUrl = `${process.env.SHOPIFY_URL}/api/${process.env.SHOPIFY_API_VERSION}/graphql.json`
    this.headers = {
      'X-Shopify-Storefront-Access-Token': `${process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }
  }

  get(
    id: string,
    conf: {
      pageSize: number
      filters?: any
      onlyDatesInMetafield?: boolean
    } = { pageSize: 100 },
    lang: string,
    cursor?: string
  ) {
    const shopifyId = ShopifyBase64.encode(SHOPIFY_TYPES.COLLECTION, id)

    if (conf.onlyDatesInMetafield) {
      return axios
        .post(
          this.baseUrl,
          {
            query: `query collection($id: ID!) {
              node(id: $id) {
                ... on Collection {
                  id
                  image {
                    list: url(transform: {crop: CENTER, maxWidth: 480, maxHeight: 790, scale: 1})
                    list_2x: url(transform: {crop: CENTER, maxWidth: 480, maxHeight: 790, scale: 2})
                    half: url(transform: {crop: CENTER, maxWidth: 720, maxHeight: 790, scale: 1})
                    half_2x: url(transform: {crop: CENTER, maxWidth: 720, maxHeight: 790, scale: 2})
                    full: url(transform: {crop: CENTER, maxWidth: 1600, maxHeight: 790, scale: 1})
                    full_2x: url(transform: {crop: CENTER, maxWidth: 1600, maxHeight: 790, scale: 2})
                  }
                  metafields(identifiers:[
                    {namespace: "stock", key: "start_date_web"},
                    {namespace: "stock", key: "end_date"},
                    {namespace: "stock", key: "teasing_date"}
                  ]) {
                    key
                    type
                    namespace
                    value
                  }
                }
              }
            }`,
            variables: {
              id: shopifyId,
            },
          },
          {
            headers: {
              ...this.headers,
              'Accept-Language': lang.substring(0, 2),
            },
          }
        )
        .then((r) => {
          if (r.status !== 200) {
            throw new Error(`Error : ${JSON.stringify(r.data)}`)
          }

          if (r.data.errors) {
            throw new Error(`Error : ${JSON.stringify(r.data.errors)}`)
          }

          return r.data.data.node
        })
    }

    const productsConfig = [`first: ${conf.pageSize}`]

    if (cursor) {
      productsConfig.push(`after: "${cursor}"`)
    }

    if (conf.filters) {
      const ff = [`{available: true}`]

      if (conf.filters.productType) {
        ff.push(`{productType: "${conf.filters.productType}"}`)
      }

      if (conf.filters.sizes) {
        for (const size of conf.filters.sizes) {
          ff.push(`{variantOption: {name: "size", value: "${size}"}}`)
        }
      }

      productsConfig.push(`filters: [${ff.join(', ')}]`)
    }

    return axios
      .post(
        this.baseUrl,
        {
          query: `query collection($id: ID!) {
            node(id: $id) {
              ... on Collection {
                id
                metafield(namespace: "stock", key: "start_date_web") {
                  value
                }
                products(${productsConfig.join(', ')}) {
                  nodes {
                    ... on Product {
                      ${PRODUCTS_GRAPHQL}
                    }
                  }
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                    endCursor
                  }
                  filters {
                    id
                    label
                    type
                    values {
                      id
                      label
                      count
                      input
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            id: shopifyId,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((r) => {
        if (r.status !== 200) {
          throw new Error(`Error : ${JSON.stringify(r.data)}`)
        }

        if (r.data.errors) {
          throw new Error(`Error : ${JSON.stringify(r.data.errors)}`)
        }

        return r.data.data.node
      })
  }

  getFilters(id: string, lang: string) {
    const shopifyId = ShopifyBase64.encode(SHOPIFY_TYPES.COLLECTION, id)

    return axios
      .post(
        this.baseUrl,
        {
          query: `query Facets($id: ID!) {
            collection(id: $id) {
              products(first:100) {
                filters {
                  id
                  label
                  type
                  values {
                    id
                    label
                    count
                    input
                  }
                }
              }
            }
          }`,
          variables: {
            id: shopifyId,
          },
        },
        {
          headers: { ...this.headers, 'Accept-Language': lang.substring(0, 2) },
        }
      )
      .then((r) => {
        if (r.status !== 200) {
          throw new Error(`Error : ${JSON.stringify(r.data)}`)
        }

        if (r.data.errors) {
          throw new Error(`Error : ${JSON.stringify(r.data.errors)}`)
        }

        return r.data.data.collection.products.filters
      })
  }

  // private buildQueryUrl(
  //   id: string,
  //   filters: Map<FacetType, any[]>,
  //   sorting?: string
  // ): URL {
  //   const uri = new URL(`${this.baseUrl}/search`)

  //   uri.searchParams.set('apiKey', this.apiKey)
  //   uri.searchParams.set('collection', id)
  //   uri.searchParams.set('getProductDescription', '0')
  //   uri.searchParams.set('q', '')

  //   // build facets
  //   const facetFilters = this.getfacetFilters(filters)
  //   if (Object.keys(facetFilters).length > 0) {
  //     uri.searchParams.set('facetFilters', JSON.stringify(facetFilters))
  //   }

  //   if (sorting) {
  //     uri.searchParams.set('sorting', sorting)
  //   }

  //   return uri
  // }

  // private getfacetFilters(filters: Map<FacetType, any[]>): Object {
  //   const res: any[] = []
  //   filters.forEach((value, key) => {
  //     const facet = this.availableFacets.get(key)

  //     switch (key) {
  //       case FacetType.PRICE:
  //         // TODO
  //         if (value.length >= 2) {
  //           if (facet) {
  //             res[facet.id] = [facet.facetName, [`:${value[0]} :${value[1]}`]]
  //           }
  //         }
  //         break
  //       case FacetType.COLLETIONS:
  //         // Collections are handled outsite facets
  //         break

  //       default:
  //         if (facet) {
  //           res[facet.id] = [facet.facetName, value]
  //         }
  //         break
  //     }
  //   })
  //   return { ...res }
  // }

  // public async getProducts(
  //   id: string,
  //   filters: Map<FacetType, any[]>,
  //   sorting?: string
  // ) {
  //   const skip = 0
  //   const nbItems = 28

  //   let uri = this.buildQueryUrl(id, filters, sorting)

  //   uri.searchParams.set('skip', `${skip}`)
  //   uri.searchParams.set('take', `1`)

  //   if (this.availableFacets.size === 0) {
  //     const { data: collection } = await axios.get(uri.toString())
  //     if (collection.data) {
  //       this.loadFacets(collection.data.facets)
  //       uri = this.buildQueryUrl(id, filters, sorting)
  //       uri.searchParams.set('skip', `${skip}`)
  //     }
  //   }

  //   uri.searchParams.set('take', `${nbItems}`)

  //   return await axios.get(uri.toString()).then((r) => {
  //     if (r.data.data) {
  //       const collection = r.data.data

  //       if (collection.total > nbItems) {
  //         const nbPages = Math.ceil(collection.total / nbItems)
  //         const pp = []

  //         for (let i = 1; i < nbPages; i++) {
  //           uri.searchParams.set('skip', `${nbItems * i}`)
  //           pp.push(axios.get(uri.toString()))
  //         }

  //         return Promise.all(pp).then((rr) => {
  //           rr.forEach((r) => {
  //             if (r.status === 200 && r.data.data) {
  //               collection.items = collection.items.concat(r.data.data.items)
  //             }
  //           })

  //           this.loadFacets(collection.facets)
  //           return collection
  //         })
  //       }

  //       this.loadFacets(collection.facets)
  //       return collection
  //     }
  //   })
  // }

  // private loadFacets(facets: IFacet[]): void {
  //   for (const item of facets) {
  //     const facet = new Facet(item)
  //     if (this.availableFacets.has(facet.facetName)) {
  //       const actualFacet = this.availableFacets.get(facet.facetName) as Facet
  //       actualFacet.labels = facet.labels
  //       this.availableFacets.set(facet.facetName, actualFacet)
  //     } else {
  //       this.availableFacets.set(facet.facetName, facet)
  //     }
  //   }
  // }
}

export default new CollectionService()
